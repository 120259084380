a {
  text-decoration: none;
  color: #056597;
}

h3 {
  color: #fff;
  font-weight: 900;
  text-transform: uppercase;
}

.container {
  display: flex;
  align-items: center;
  height: 600px;
  border: 2px solid #006100;
}

.text-center {
  text-align: center;
}

.action {
  width: 350px;
  border-radius: 25px !important;
  margin-bottom: 15px !important;
}

.action-container {
  margin-top: auto;
}

@media only screen and (max-width: 600px) {
  .action-container {
    width: 100%;
  }
}

.center-container {
  margin-top: auto;
  margin-bottom: auto;
  text-align: center;
}

form,
.panel {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.panel {
  justify-content: center;
}

form > * {
  justify-content: center;
}

img + .placeholder {
  display: none !important;
}

@media only screen and (min-width: 601px) {
  .grid-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: start;
  }
}

.grid-container a {
  margin: 10px;
}

.card {
  height: 160px;
}

.card-title {
  max-width: 200px;
}

@media print {
  .no-print {
      display: none !important;
  }
  .print-this {
      display: block !important;
  }
}